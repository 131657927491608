<template>
  <div class="login-component">
    <div class="image-container"></div>
    <transition appear name="fadeRight" mode="out-in">
        <form @submit.prevent="login" id="login-form" class="login-form black-background">
          <div class="container">
            <h2>{{lang.login.headline}}</h2>
            <div class="md-fields-comtainer">
              <md-field class="md-field-icon">
                <i class="fas fa-user-alt"></i>
                <label>{{lang.spielerForm.labelEMail}}</label>
                <md-input v-model.trim="auth.email" id="email" :name="lang.spielerForm.labelEMail" type="email" autocomplete="email"></md-input>
              </md-field>
              <md-field class="md-field-icon">
                <i class="fas fa-unlock"></i>
                <label>{{lang.spielerForm.labelPasswort}}</label>
                <md-input v-model="auth.password" id="passwort" :name="lang.spielerForm.labelPasswort" type="password" autocomplete="current-password"></md-input>
              </md-field>
            </div>
            <div class="button-list">
              <md-button type="submit" class="md-primary md-round md-raised md-center md-filled">
                {{lang.login.buttonText1}}
              </md-button>
              <md-button :to="{name: 'CreateAccount'}" class="md-primary md-round md-raised md-center md-border">
                {{lang.login.buttonText2}}
              </md-button>
            <router-link v-if="isOnline" class="link-forgot-password" :to="{name: 'ForgotPasswordEmail'}">{{lang.spielerForm.linkForgotPassword}}</router-link>
            </div>
          <LanguageChanger :cssLight="false"/>
          </div>
        </form>
    </transition>
    <SubmitModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'
import LanguageChanger from '../Utils/LanguageChanger'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'LoginForm',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    LanguageChanger
  },
  data() {
    return {
      auth: {
        email: '',
        password: ''
      },
    }
  },
  methods: {
    async login() {
      if (this.isOnline) {
        await store.dispatch('login', this.auth)
         .then(result => {
           if (result.data != null) {
               router.push({name: "Home"});
           }
           else {
             this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
           }
         })
         .catch(error => {
             this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
         });
      } 
      else {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/base';

.link-forgot-password {
  color: $color-fliestext !important;
  display: block;
  font-size: 1.3rem;
  text-align: center;
}

.image-container {
  background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-5.jpg");
  background-position: 0% -3%;
}

.login-form {
  .button-list {
    margin-top: 50px;
  }
}

@media (min-width: $screen-xs2) {
  .image-container {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
    background-position: 0% -10%;
  }
}


@media (min-width: $screen-sm) {
  .login-component {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-2.jpg");
    background-position: center bottom;
  }

  .login-form {
    .button-list {
      margin-top: 60px;
    }
  }
}

@media (min-width: $screen-lg) {
  .login-component {
      background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-2.jpg");
      background-position: center bottom;
  }
}
</style>
