<template>
    <div>
        <LoginForm/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm.vue'
import store from '@/store/index'

export default {
  name: 'Login',
  components: {
    LoginForm
  },
  computed: {
    loadingStatus() {
      return store.state.spieler.loadingStatus;
    },
  },
}
</script>
