<template>
  <div class="language-selector" :class="cssLight ? 'light': ''">
    <md-icon>language</md-icon>
    <md-button :class="selectedLanguage=='de' ? 'md-primary': ''" @click="selectedLanguage='de'">DE</md-button>
    <md-button :class="selectedLanguage=='en' ? 'md-primary': ''" @click="selectedLanguage='en'">EN</md-button>
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  name: 'LanguageChanger',
  props: {
    cssLight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      selectedLanguage: ""
    }
  },
  created() {
    this.selectedLanguage = this.getLocalStorageVariable("language") != "" ? this.getLocalStorageVariable("language") : 'de';
  },
  watch: {
    selectedLanguage(lang) {
      store.commit("SET_LANGUAGE", lang);
    }
  },
  methods: {
    getLocalStorageVariable(localStorageVariableName) {
      return localStorage.getItem(localStorageVariableName) ? localStorage.getItem(localStorageVariableName) : '';
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base';

.language-selector {
  align-items: center;
  display: flex;
  justify-content: center;

  .md-icon {
    margin: 10px 4px 10px 4px;
  }

  .md-button {
    color: $color-fliestext;
    height: 40px;
    margin: 6px 2px;
    min-width: 20px;
    padding: 0px;

    &.md-primary {
      color: $brand-primary;
    }

  }

  &.light {
    .md-icon {
      color: $color-white;
    }

    .md-button {
      color: $color-white;

      &.md-primary {
        color: $brand-primary;
      }
    }
  }

}
</style>